import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@system42/core";
import { ButtonLink, Headline2Sans, Headline3Serif } from "../../system42";
import * as styles from "./styles.module.css";

export function ThreeColumnsIcons(props) {
  const {
    title,
    titleWidth,
    subtitle,
    col1Image,
    col1Alt,
    col1Heading,
    col1Text,
    col1LinkTo,
    col1LinkHref,
    col1LinkText,
    col2Image,
    col2Alt,
    col2Heading,
    col2Text,
    col2LinkTo,
    col2LinkHref,
    col2LinkText,
    col3Image,
    col3Alt,
    col3Heading,
    col3Text,
    col3LinkTo,
    col3LinkHref,
    col3LinkText,
    className,
  } = props;

  return (
    <div className={cn(className, styles.threeColumnsDefault)}>
      <Headline2Sans className={styles.title} style={{ maxWidth: titleWidth }}>
        {title}
      </Headline2Sans>
      <p className={styles.subtitle}>{subtitle}</p>
      <div className={styles.columns}>
        <div className={cn(styles.column, styles.column1)}>
          <div className={styles.icon}>
            <img src={col1Image} alt={col1Alt} />
          </div>
          <Headline3Serif className={styles.columnHeading}>
            {col1Heading}
          </Headline3Serif>
          <p className={styles.text}>{col1Text}</p>
          {col1LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col1LinkTo ? Link : "a"}
              to={col1LinkTo}
              href={col1LinkHref}
            >
              {col1LinkText}
            </ButtonLink>
          )}
        </div>
        <div className={cn(styles.column, styles.column2)}>
          <div className={styles.icon}>
            <img src={col2Image} alt={col2Alt} />
          </div>
          <Headline3Serif className={styles.columnHeading}>
            {col2Heading}
          </Headline3Serif>
          <p className={styles.text}>{col2Text}</p>
          {col2LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col2LinkTo ? Link : "a"}
              to={col2LinkTo}
              href={col2LinkHref}
            >
              {col2LinkText}
            </ButtonLink>
          )}
        </div>
        <div className={cn(styles.column, styles.column3)}>
          <div className={styles.icon}>
            <img src={col3Image} alt={col3Alt} />
          </div>
          <Headline3Serif className={styles.columnHeading}>
            {col3Heading}
          </Headline3Serif>
          <p className={styles.text}>{col3Text}</p>
          {col3LinkText && (
            <ButtonLink
              className={styles.link}
              customTag={col3LinkTo ? Link : "a"}
              to={col3LinkTo}
              href={col3LinkHref}
            >
              {col3LinkText}
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
}
