// extracted by mini-css-extract-plugin
export var column = "styles-module--column--613fb";
export var column1 = "styles-module--column1--9aeb3";
export var column2 = "styles-module--column2--d0564";
export var column3 = "styles-module--column3--9f510";
export var columnHeading = "styles-module--columnHeading--40ece";
export var columns = "styles-module--columns--3831f";
export var icon = "styles-module--icon--dca54";
export var link = "styles-module--link--6db82";
export var subtitle = "styles-module--subtitle--31605";
export var text = "styles-module--text--53cc3";
export var threeColumnsDefault = "styles-module--threeColumnsDefault--baf86";
export var title = "styles-module--title--ae587";